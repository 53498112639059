<template>
  <BModal :open="modalOpen" centered modal-class="default-backdrop askToConfirm" size="md"
          @hide="hideModal" :custom-backdrop="true" :hide-backdrop="true">
    <div class="hch_modal-content">
      <HCHModalHeader :close="hideModal"/>

      <form method="post" @submit.prevent="callPropsAction" v-if="props !== null">
        <div class="hch_modal-body">

          <p v-if="props.description && props.description.length > 0">
            {{ props.description }}
          </p>
          <p v-else>
            {{ $t('ask-co-confirm-default-description') }}
          </p>

          <div class="form-group" v-if="props.textarea_caption && props.textarea_caption.length > 0">
            <label for="ask_to_confirm_txt" class="required">
              {{ props.textarea_caption }}
            </label>
            <textarea required name="ask_to_confirm_txt" id="ask_to_confirm_txt" class="form-control" v-model="callbackData.ask_to_confirm_txt" rows="3"></textarea>
          </div>

        </div>
        <div class="hch_modal-footer d-flex justify-content-between">
          <button :class="props.cancelButtonClass ? props.cancelButtonClass : 'btn rounded mx-2 w-md-50 btn-outline-first'"
                  type="button" @click="hideModal">
            {{ $t('collapse-close') }}
          </button>
          <button type="submit" v-if="props.action !== undefined"
                  :class="props.submitButtonClass ? props.submitButtonClass : 'btn btn-outline-danger mx-2 rounded w-md-50'">
            {{
              props.action_button_caption && props.action_button_caption.length > 0 ? props.action_button_caption : $t('ask-to-confirm-default-accept')
            }}
          </button>
        </div>
      </form>
    </div>

  </BModal>
</template>

<script setup lang="ts">
import HCHModalHeader from "~/components/web/parts/modals/hch_modal_header";
import BModal from "~/components/web/parts/b-modal.vue";
import {computed, ref, watch} from "vue";
import {askForConfirmPopup} from "../../../composables/askForConfirmPopup";

const {confirmMessage} = askForConfirmPopup();
const callbackData = ref({});

const modalOpen = computed(() => {
  return confirmMessage.value !== null;
});

const props = computed(() => {
  return confirmMessage.value
});

watch(modalOpen, (val, valP) => {
  if (valP === null) {
    callbackData.value = {};
  }
})

const hideModal = () => {
  confirmMessage.value = null;
}

const callPropsAction = () => {
  props.value.action(callbackData.value);
  confirmMessage.value = null;
}

</script>


<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light p-0 fixed-top">

    <div class="header w-100 d-flex align-items-center justify-content-between">

      <button class="btn btn-rounded mb-0 d-flex align d-md-none" @click="backAction">
        <TyIcon icon="ty-arrow-left" color="black" width="1.3rem" class="pointer"/>
      </button>

      <a :href="`/${activeLocale.code}-${activeWebCountry.code}`"
         @click="preloadingRedirect"
         class="navbar-brand mx-auto align-items-center d-flex" title="Back to home page">
        <img :src="`/img/logo_${activeWebCountry.code}.svg`" :alt="$t('main-logo-title-alt')">
      </a>

      <div class="d-lg-none btn btn-first mt-0 menu-toggler align-items-center justify-content-center d-flex px-3"
           @click="toggleMenu">
        <TyIcon icon="ty-menu-2" width="2rem" :class="{'d-none': visibleMenu}" v-if="!visibleMenu"/>
        <TyIcon icon="ty-times" width="1rem" color="#fff" class="" :class="{'d-block': visibleMenu}"
                v-if="visibleMenu"/>
      </div>
    </div>

    <div class="main-menu row mt-lg-4 align-content-start" :class="{'open': visibleMenu}">

      <div class="col-6 col-lg-12 mb-3 mb-md-0" @click="visibleMenu= false">
        <NuxtLink :to="`/${activeLocale.code}/admin/user`" class="card mx-2">
          <div class="card-body">
            <div class="row d-flex align-items-center">

              <div class="icon-holder col-12 col-lg-2 text-center d-flex justify-content-center">
                <TyIcon icon="ty-user" width="1.5rem"/>
              </div>

              <div class="menu-title col-12 col-lg d-flex align-content-center position-relative">
                <p class="d-inline-flex fw-bolder lh-1 lh-lg-0 mb-0">{{ client.client_name }}</p>

                <div class="credit-info" v-if="client.type==='owner'">
                  <div v-if="client.credit.value > 1 && client.activated === false">
                    (&nbsp;{{ Math.round(client.credit.value * defaultLocaleCurrency.creditConversion) }}&nbsp;{{defaultLocaleCurrency.mark}}
                    {{ $t('kredit') }}&nbsp;)
                  </div>
                  <div v-else-if="client.activated">
                    <span class="badge bg-second mt-0">
                      {{
                        $t('verified') + (client.credit.value > 1 ? (' ( ' + Math.round(client.credit.value * defaultLocaleCurrency.creditConversion) + '' + defaultLocaleCurrency.mark + ' ' + $t('kredit') + ' )') : '')
                      }}
                    </span>
                  </div>
                  <div v-else>
                    {{ $t('not-verified') }}
                  </div>

                </div>
                <NuxtLink :to="`/${activeLocale.code}/admin/user/verify`" class="credit-info"
                          v-if="client.verified === false">
                  <span class="badge bg-danger">{{ $t('not-yer-verified-client') }}</span>
                </NuxtLink>

              </div>
            </div>
          </div>

        </NuxtLink>
      </div>

      <template v-for="menuItem in menuItems">

        <div class="col-6 col-lg-12 item mb-3 mb-lg-0" @click="visibleMenu= false"
             v-if="(menuItem.class.includes('client') && client.type ==='client')  || (menuItem.class.includes('owner') && client.type ==='owner') || (!menuItem.class.includes('owner') && !menuItem.class.includes('client'))">
          <NuxtLink :to="menuItem.getLinkWithSlash()" :class="{'active': isMenuItemActive(menuItem)}"
                    :title="menuItem.name" class="card mx-2">
            <div class="card-body px-3 py-3">
              <div class="row d-flex justify-content-center justify-content-lg-start align-content-center">

                <div
                    class="icon-holder col-12 col-lg-2 text-center justify-content-center text-lg-start align-items-center d-flex position-relative">
                  <TyIcon :icon="menuItem.icon" width="1.5rem"/>
                  <div class="badge badge-pill bg-danger message-badge"
                       v-if="menuItem.class.indexOf('message') > -1 && unreadMessageCount > 0">
                    {{ unreadMessageCount }}
                  </div>
                  <div class="badge badge-pill bg-danger message-badge"
                       v-if="menuItem.class.indexOf('reservations') > -1 && reservationsToProcess > 0">
                    {{ reservationsToProcess }}
                  </div>
                </div>

                <div
                    class="menu-title col-12 col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                  <p class="fw-bolder mb-0 lh-1 lh-lg-0 text-center">{{ menuItem.name }}</p>
                </div>
              </div>

            </div>
          </NuxtLink>
        </div>
      </template>

      <div class="col-6 col-lg-12">
        <div class="card mx-2" @click="languageSwitcherModal = true;">
          <div class="card-body px-3 py-3">
            <div class="row d-flex justify-content-center justify-content-lg-start align-content-center">

              <div
                  class="icon-holder  col-12 col-lg-2 text-center text-lg-start d-flex align-items-center justify-content-center">
                <TyIcon icon="ty-language" width="1.5rem" height="1.5rem"/>
              </div>
              <div
                  class="menu-title col-12 col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                <p class="d-flex fw-bolder mb-0 gap-1 justify-content-center align-items-center pointer">
                  <span>{{ $t('language') }}</span>
                  <small class="small bg-silver badge fw-normal text-dark m-0">{{ activeLocale.code.toUpperCase() }} | {{ defaultLocaleCurrency.mark }}</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-12 pab-1 mb-3">
        <a href="#" @click="logout" class="card logout mx-2">
          <div class="card-body px-3 py-3">
            <div class="row d-flex justify-content-center justify-content-lg-start align-content-center">

              <div
                  class="icon-holder btn-logout col-12 col-lg-2 text-center text-lg-start d-flex align-items-center justify-content-center">
                <TyIcon icon="ty-logout" width="1.5rem" height="1.5rem"/>
              </div>
              <div
                  class="menu-title col-12 col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                <p class="d-inline-flex fw-bolder mb-0">{{ $t('logout') }}</p>
              </div>
            </div>
          </div>
        </a>
      </div>

      <button class="btn btn-block close-button py-3 text-start fw-bolder" type="button" @click="visibleMenu = false">
        <span>{{ $t('close-menu') }}</span>
        <TyIcon icon="ty-times-o" width="2rem" class="px-0 icon-color-first close-icon"/>
      </button>

    </div>


  </nav>

  <BModal :open="languageSwitcherModal" centered modal-class="default-backdrop askToConfirm" size="md"
          @hide="languageSwitcherModal = false" :custom-backdrop="true" :hide-backdrop="true">
    <div class="hch_modal-content">
      <HCHModalHeader :close="() => languageSwitcherModal = false"/>

      <div class="hch_modal-body">

        <p class="mb-2 fw-semibold">
          {{ $t('desc2-modal-lang-switcher') }}
        </p>

        <select id="modal_desktop_menu_country_picker" class="form-select picker custom-select mb-4"
                v-model="selectedLocale" required>
          <option :value="locale" v-for="locale in locales.filter(l => l.enabled === true)">
            {{ $t(`lang-country-picker-lang-${locale.code}`) }}
          </option>
        </select>
        <div class="text-end">
          <button class="btn btn-first text-end" type="button" @click="changeLocale">
            {{ $t('select') }}
          </button>
        </div>

      </div>

    </div>

  </BModal>

  <PartsTempPreloader v-if="preloading"/>
</template>

<script lang="ts" setup>
import {obtainActiveLocale, obtainActiveWebCountry} from "../../../composables/master_page";
import {ref, computed, PropType, watch, onMounted, onUnmounted} from "vue";
import Client from "../../../lib/models/Client";
import {MenuItem} from "~/lib/models/MenuItem";
import {useStateFunctionBodyClass} from "../../../composables/state/body_class";
import {useStateSocket} from "../../../composables/state/socket";
import {navigateTo, useNuxtApp, useRoute, useRouter, useState} from "#app";
import {useAuth} from "../../../composables/auth/useAuth";
import {useStateAdmin} from "../../../composables/state/admin";
import {getDefaultLocaleCurrency} from "../../../lib/functions";
import HCHModalHeader from "../../web/parts/modals/hch_modal_header.vue";
import BModal from "../../web/parts/b-modal.vue";
import locales from "~/lib/data/locales.json";
import currencies from "~/lib/data/currencies.json";

const props = defineProps({
  client: Object as PropType<Client>
})

const {updateBodyClass, removeBodyClass} = useStateFunctionBodyClass();
const {unreadMessageCount} = useStateSocket();
const {stepBackAction} = useStateAdmin();
const {$tyrionApi} = useNuxtApp();
const {$socketApi} = useNuxtApp();
const activeLocale = obtainActiveLocale();
const activeWebCountry = obtainActiveWebCountry();
const visibleMenu = ref(false);
const preloading = ref(false);
const selectedLocale = ref(locales.find(c => c.code === activeLocale.code));
const languageSwitcherModal = ref(false);
const reservationsToProcess = ref(0);
const reservationTimout = ref<null | number>(null);
const menuItems = ref<Array<MenuItem>>([]);

const defaultLocaleCurrency = computed(() => getDefaultLocaleCurrency(activeLocale.code, currencies));

watch(visibleMenu, (currentVal) => {
  if (currentVal === true) {
    updateBodyClass('no-scroll');
  } else {
    removeBodyClass('no-scroll');
  }
});


onMounted(() => {
  $tyrionApi.getTyrionUrl(`/api3/widget/menu/admin_sidebar`, {query: {langCode: activeLocale.code}}).then(response => {
    response.data.content.map(m => {
      menuItems.value.push((new MenuItem()).fromObject(m) as MenuItem);
    })
  });
  loadUnfinishedReservations();
})

onUnmounted(() => {
  if (reservationTimout.value !== null) clearTimeout(reservationTimout.value);
});


const isMenuItemActive = (menuItem) => {
  return menuItem.link === useRoute().path;
};

const toggleMenu = () => {
  visibleMenu.value = !visibleMenu.value;
};

const logout = async () => {
  removeBodyClass('no-scroll');
  // useState('first_preload').value = true;
  await useAuth().logout();
  navigateTo(`/${activeLocale.code}-${activeWebCountry.code}`);
};


const loadUnfinishedReservations = () => {

  const ttAction = () => {
    $tyrionApi.postTyrionUrl('/api3/reservations/obtain', {
      body: {
        filter: {
          owner_only: true,
          type: {
            temp: true,
          }
        },
        options: {days: false, object: false}
      },
      userTokenRequired: true
    }).then(response => {
      reservationsToProcess.value = response.data && response.data.reservations ? response.data.reservations.length : 0;
    })
  }

  ttAction();
  if (reservationTimout.value !== null) clearInterval(reservationTimout.value);
  reservationTimout.value = setInterval(() => {
    ttAction();
  }, 10000)

};

const preloadingRedirect = () => {
  preloading.value = true;
}

const changeLocale = () => {
  const urlObj = new URL(window.location.href);
  window.location.href = urlObj.pathname.replace(`/${activeLocale.code}/`, `/${selectedLocale.value.code}/`);
}

const backAction = () => {
  if (visibleMenu.value) {
    visibleMenu.value = false;
  } else {
    if (stepBackAction.value !== null) {
      stepBackAction.value();
    } else {
      useRouter().back();
    }
  }
};


</script>

<template>

    <div class="container-fluid">
        <Navbar :client="client"/>
        <main id="admin_content" class="row">
            <div class="col-12 col-md-6 col-xl-4">
                <div class="alert alert-danger mt-lg-5" v-if="client && client.type === 'owner' && !client.activated">
                    <NuxtLink :to="`/${activeLocale.code}/admin/user/activation`">
                        <div class="row align-items-center">
                            <div class="col-12 mb-3">
                                <span>{{ $t('not-activated-client-warning') }}</span>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-danger btn-sm rounded w-100">
                                    {{ $t('activate-profil') }}
                                </button>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
            </div>
            <div class="col-12 mb-5 pb-5">

                <slot/>

            </div>

        </main>
        <ClientOnly>
            <AskToConfirmModal/>
        </ClientOnly>
    </div>


</template>

<script setup lang="ts">
import "@/assets/less/admin/style.min.css";
import Navbar from "../components/admin/partial/navbar";
import {obtainActiveLocale} from "../composables/master_page";
import {getCurrentUser} from "../composables/auth/useAuthUser";
import AskToConfirmModal from "~/components/admin/partial/ask_to_action_confirm.vue";
import {onMounted} from "vue";
import {GATracker} from "../composables/ga_tracker";

const activeLocale = obtainActiveLocale();
const client = getCurrentUser();
const {initGTM} = GATracker();


onMounted(() => {
    initGTM();
})


</script>
